import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { CrmSafeAny } from 'common-module/core/types';
import { cloneDeep, omit } from 'lodash-es';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { JournalApiService } from '~/api/journal/journal-api.service';
import { JournalModel } from '~/api/journal/journal.model';

import { stringifyCircularJSON } from '../../../utils/object/stringify';
import {
  ErrorEvent,
  EventOrigin,
  EventSeverity,
  HttpPerformanceEvent,
  HttpPerformanceRequest,
} from '../model/error.event';
import { JournalEvent } from '../model/journal.event';

import { ErrorTrackingBuilderService } from './error-tracking-builder.service';

const ERROR_STACK_KEY = '_crm-error-stack';

@Injectable()
export class ErrorTrackingService {
  private _errorStack: JournalEvent[] = [];
  private _stackClear$ = new Subject<void>();
  private _retrying = false;

  private journalService = inject(JournalApiService);
  private eventBuilderService = inject(ErrorTrackingBuilderService);

  public init() {
    this.eventBuilderService.init();

    const localStack = localStorage.getItem(ERROR_STACK_KEY);
    this._errorStack = localStack ? JSON.parse(localStack) : [];

    if (this._errorStack.length) {
      this.retryStack();
    }
  }

  public addEvent(event: JournalEvent) {
    this._errorStack.push(event);
    this.persistStack();
    if (this._errorStack.length) {
      this.retryStack();
    }
  }

  /**
   * Checks if event with same level of severity exists in stack
   * @param event
   */
  public existInStack(event: JournalEvent): boolean {
    return !!this._errorStack.find(
      (es) => es.subject === event.subject && es.level === event.level,
    );
  }

  public removeEvent(event: JournalEvent): void {
    const index = this._errorStack.findIndex((es) => es._id === event._id);
    if (index >= 0) {
      const tmp = cloneDeep(this._errorStack);
      tmp.splice(index, 1);
      this._errorStack = [...tmp];
      this.persistStack();
    }
  }

  public trackHttpPerformance(
    request: HttpPerformanceRequest,
    subject: string,
    severity: EventSeverity,
  ) {
    const event = this.eventBuilderService.buildHttpPerformanceEvent({
      request,
    });

    this.trackEvent(event, subject, EventOrigin.http, severity);
  }

  public trackRuntimeError(error: Error, severity: EventSeverity): void {
    const event = this.eventBuilderService.buildErrorEvent({ error });

    this.trackEvent(event, error.message, EventOrigin.runtime, severity);
  }

  public trackSocketError(body: CrmSafeAny, severity: EventSeverity): void {
    const event = this.eventBuilderService.buildErrorEvent({ error: body });

    this.trackEvent(event, 'Socket error', EventOrigin.socket, severity);
  }

  public trackHttpError(
    error: HttpErrorResponse,
    severity: EventSeverity,
  ): void {
    const event = this.eventBuilderService.buildErrorEvent({ error });

    this.trackEvent(event, error.message, EventOrigin.http, severity);
  }

  protected buildJournalEvent(data: {
    subject: string;
    event: ErrorEvent | HttpPerformanceEvent;
    origin: EventOrigin;
    severity: EventSeverity;
  }): JournalEvent {
    const { origin, event, subject, severity } = data;
    return {
      _id: v4(),
      level: severity,
      ref: event.session.id,
      subject,
      tags: [origin],
      meta: event,
    };
  }

  // eslint-disable-next-line max-params
  protected trackEvent(
    event: ErrorEvent | HttpPerformanceEvent,
    subject: string,
    origin: EventOrigin,
    severity: EventSeverity,
  ) {
    const journalEvent = this.buildJournalEvent({
      event,
      subject,
      origin,
      severity,
    });

    if (this.existInStack(journalEvent)) {
      return;
    }

    this.postEvent(journalEvent)
      .pipe(
        catchError((err) => {
          this.addEvent(journalEvent);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  protected postEvent(event: JournalEvent): Observable<JournalModel> {
    return this.journalService.logError(omit(event, '_id'));
  }

  protected persistStack(): void {
    const stackValue = stringifyCircularJSON(this._errorStack);
    localStorage.setItem(ERROR_STACK_KEY, stackValue);
  }

  protected retryStack(): void {
    if (this._retrying) {
      return;
    }

    const tryPostStack = () => {
      return of(...this._errorStack).pipe(
        mergeMap((event) =>
          this.postEvent(event).pipe(
            catchError(() => of(false)),
            filter((res) => !!res),
            tap(() => {
              this.removeEvent(event);
              if (!this._errorStack.length) {
                this._retrying = false;
                this._stackClear$.next();
              }
            }),
          ),
        ),
      );
    };

    this._retrying = true;
    timer(5000, 5000)
      .pipe(
        takeUntil(this._stackClear$),
        switchMap(() => tryPostStack()),
        share(),
      )
      .subscribe();
  }
}
