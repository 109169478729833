import { Duration } from 'date-fns';

export enum EventOrigin {
  http = 'http',
  socket = 'socket',
  runtime = 'runtime',
}

export enum HttpEventSubject {
  slowRequest = 'slow-request',
  bigRequest = 'big-request',
}

export interface BaseEvent {
  app: EventApp;
  user: EventUser;
  session: EventSession;
  browser: EventBrowser;
  createdAt: string;
}

export interface EventApp {
  name: string;
  env: string;
  version: string;
  state: {
    route: string;
  };
}

export interface EventUser {
  id: string;
  loggedAt: string;
}

export interface EventSession {
  id: string;
  duration: Duration;
  domain: string;
}

export interface EventBrowser {
  userAgent: string;
  platform: Record<string, boolean>;
}

export interface ErrorAction {
  tagName: string;
  className: string;
  text?: string;
}

export interface ErrorEvent extends BaseEvent {
  error: Error;
  actions: ErrorAction[][];
}

export interface HttpPerformanceRequest {
  url: string;
  size?: number;
  time?: number;
}

export interface HttpPerformanceEvent extends BaseEvent {
  request: HttpPerformanceRequest;
}

export enum EventSeverity {
  critical = 'critical',
  error = 'error',
  info = 'info',
  warning = 'warning',
}
